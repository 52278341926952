/*sidebar*/
.sidebar {
    width: 300px;
    background-color: #191919;
    font-size: 14px;
}

.tab ul li {
    /*width: 140px;*/
    height: 40px;
    padding: 0 30px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    background-color: #ebebeb;
    font-size: 15px;
}

article .option .second-line .text-field button {
    padding: 7px 30px;
}

.fixed-table table th {
    position: sticky;
    top: 0;
    /*border-left: 0.5px solid #d8d8d8;*/
    border-right: 0.5px solid #d8d8d8;
    background-color: #00245f;
    /* z-index: 100; */
    color: #ffffff;
    vertical-align: middle;
    padding: 3px 8px 4px 8px;
    font-size: 13px;
    /* font-weight: bold; */
    text-align: left;
    font-family: NotoSansKRMedium sans-serif;
    /*height: 28px;*/
    /*line-height: 28px;*/
}

.mypage article .user-info .account-info .text-field {
    width: 300px;
    height: auto;
    /*border-bottom: 2px solid #eee;*/
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-flow: column;
    gap: 5px;
}

.fixed-table th button {
    font-size: 13px;
    /* justify-content: left; */
    padding: 0;
    min-width: auto;
    text-align: left;
    /*position: absolute;*/
    /*top: 48%;*/
    /*transform: translateY(-50%);*/
}

.bot-appbar {
    width: 100%;
    height: auto;
    position: relative;
    background-color: #262626;
    padding: 10px 0;
}

.appbar .ul-wrap {
    display: flex;
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    flex-flow: wrap;
    gap: 5px;
}

.appbar .top-appbar .ul-wrap {
    /*right: 200px;*/
    /*left: auto;*/
    /*background-color: #323232;*/
    padding: 30px 0 8px 0;
    border-radius: 30px;

}

.break {
    flex-basis: 100%;
    height: 0;
}

.ul-title-wrap {
    /*width: 50px;*/
    height: 30px;
    position: relative;
    margin-right: 10px;
}

.ul-title {
    height: 30px;
    line-height: 30px;
    /*width: 50px;*/
    /*color: #ffffff;*/
    text-align: center;
    font-size: 12px;
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%,-50%);*/
    /*position: absolute;*/
    font-family: NotoSansKRMedium sans-serif;
    border-radius: 3px;
    padding: 0 4px;
}

.lend-borrow-list .tlist table th:nth-child(3){
    width: 100px;
}

.lend-borrow-list .tlist table th:nth-child(7){
    width: 80px;
}

.lend-borrow-list .tlist table th:nth-child(9){
    width: 160px;
}

.lend-pool-register-list .tlist table th:nth-child(4){
    width: 100px;
}

.lend-pool-register-list .tlist table th:nth-child(5){
    width: 140px;
}

.lend-pool-register-list .tlist table th:nth-child(6){
    width: 80px;
}

.lend-pool-matched .tlist table th:nth-child(3){
    width: 100px;
}

.fixed-table-confirm th:nth-child(3){
    width: 110px;
}

.fixed-table-confirm th:nth-child(4){
    width: 160px;
}

.fixed-table-confirm th:nth-child(5){
    width: 110px;
}

.borrow-pool-list .tlist table th:nth-child(3){
    width: 100px;
}

.borrow-pool-list .tlist table th:nth-child(9){
    width: 140px;
}

.borrow-request-order.order-normal table th:nth-child(7) {
    width: 140px;
}

.borrow-request-list .tlist table th:nth-child(4) {
    width: 100px;
}

.borrow-request-list .tlist table th:nth-child(11) {
    width: 120px;
}

.borrow-request-list .tlist table th:nth-child(5) {
    width: 140px;
}

.borrow-request-list .tlist table th:nth-child(6) {
    width: 70px;
}

.borrow-request-list .tlist table th:nth-child(8) {
    width: 120px;
}

.borrow-request-list .live table th:nth-child(4) {
    width: 100px;
}

.borrow-request-list .live table th:nth-child(10) {
    width: 120px;
}

.borrow-request-list .live table th:nth-child(11) {
    width: 120px;
}

.lend-pool-register-list .live table th:nth-child(4) {
    width: 100px;
}

.lend-pool-register-list .live table th:nth-child(5) {
    width: 140px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(3), .borrow-order-list.nego-requester-cancel table th:nth-child(3) {
    width: 110px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(4), .borrow-order-list.nego-requester-cancel table th:nth-child(4) {
    width: 150px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(5), .borrow-order-list.nego-requester-cancel table th:nth-child(5) {
    width: 100px;
}

.lend-order-list.nego-requester table th:nth-child(2), .borrow-order-list.nego-requester table th:nth-child(2) {
    width: 115px;
}

.lend-order-list.nego-requester table th:nth-child(3), .borrow-order-list.nego-requester table th:nth-child(3) {
    width: 155px;
}

.lend-order-list.nego-requester table th:nth-child(4), .borrow-order-list.nego-requester table th:nth-child(4) {
    width: 100px;
}

.lend-order-list.nego-requester table th:nth-child(9), .borrow-order-list.nego-requester table th:nth-child(9) {
    width: 120px;
}

.lend-search .tab-LEDGER table th:nth-child(3), .borrow-search .tab-LEDGER table th:nth-child(3) {
    width: 110px;
}

.lend-search .tab-LEDGER table th:nth-child(4), .borrow-search .tab-LEDGER table th:nth-child(4) {
    width: 150px;
}

.lend-search .tab-LEDGER table th:nth-child(5), .borrow-search .tab-LEDGER table th:nth-child(5) {
    width: 100px;
}

.lend-search .tab-RESPONSE table th:nth-child(3), .borrow-search .tab-RESPONSE table th:nth-child(3) {
    width: 110px;
}

.lend-search .tab-RESPONSE table th:nth-child(4), .borrow-search .tab-RESPONSE table th:nth-child(4) {
    width: 150px;
}

.lend-search .tab-RESPONSE table th:nth-child(5), .borrow-search .tab-RESPONSE table th:nth-child(5) {
    width: 120px;
}

.lend-order-list.nego-not-requester table th:nth-child(2),
.borrow-order-list.nego-not-requester table th:nth-child(2) {
    width: 115px;
}

.lend-order-list.nego-not-requester table th:nth-child(3),
.borrow-order-list.nego-not-requester table th:nth-child(3) {
    width: 155px;
}

.lend-order-list.nego-not-requester table th:nth-child(4),
.borrow-order-list.nego-not-requester table th:nth-child(4) {
    width: 100px;
}

.lend-order-list.nego-not-requester table th:nth-child(9),
.borrow-order-list.nego-not-requester table th:nth-child(9){
    width: 120px;
}

.lend-order-list.nego-requester table th:nth-child(10),
.borrow-order-list.nego-requester table th:nth-child(10) {
    width: 110px;
}

.lend-order-list.nego-requester table th:nth-child(11),
.borrow-order-list.nego-requester table th:nth-child(11) {
    width: 110px;
}

.lend-order-list.nego-requester table th:nth-child(12),
.borrow-order-list.nego-requester table th:nth-child(12) {
    width: 110px;
}

.lend-order-list.nego-not-requester table th:nth-child(13),
.borrow-order-list.nego-not-requester table th:nth-child(13){
    width: 100px;
}

.lend-search .tab-HISTORY table th:nth-child(2),
.borrow-search .tab-HISTORY table th:nth-child(2){
    width: 110px;
}

.lend-search .tab-HISTORY table th:nth-child(3),
.borrow-search .tab-HISTORY table th:nth-child(3){
    width: 150px;
}

.lend-search .tab-HISTORY table th:nth-child(4),
.borrow-search .tab-HISTORY table th:nth-child(4){
    width: 100px;
}

.lend-search .tab-HISTORY table th:nth-child(7),
.borrow-search .tab-HISTORY table th:nth-child(7){
    width: 140px;
}

.lend-search .tab-HISTORY table th:nth-child(8),
.borrow-search .tab-HISTORY table th:nth-child(8){
    width: 160px;
}

.lend-search .tab-HISTORY table th:nth-child(10),
.borrow-search .tab-HISTORY table th:nth-child(10){
    width: 140px;
}

.lend-search .tab-HISTORY table th:nth-child(12),
.borrow-search .tab-HISTORY table th:nth-child(12){
    width: 80px;
}

.lend-search .tab-HISTORY table th:nth-child(13),
.borrow-search .tab-HISTORY table th:nth-child(13){
    width: 120px;
}
